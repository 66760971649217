import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelObjective,
  OverallBenefits
} from 'components';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import level1Icon from 'images/Shared/LevelIcons/WhiteStroke/level_01_icon.svg';

class Level1Demo extends PureComponent {
  state = {
    componentHasMounted: false,
    sendingForm: false,
    ['Since the emergence of COVID, what are the biggest challenges your company faces in the year ahead?']: '',
    ['In your opinion, what strengths does your business possess to rise to the challenges you have outlined above?']: '',
    ['Can you suggest additional improvements and/or new ideas that could address your company’s challenges?']: ''
  };

  componentDidMount() {
    this.setState({
      componentHasMounted: true
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      sendingForm: true
    });

    const form = e.target;
    const { user, game, selectedLevel } = this.props;
    const { name, email } = user;

    axios({
      url: '/',
      method: 'POST',
      data: this.encodeData({
        'form-name': form.getAttribute('name'),
        game: game.title,
        name,
        email,
        ['Since the emergence of COVID, what are the biggest challenges your company faces in the year ahead?']: this.state['Since the emergence of COVID, what are the biggest challenges your company faces in the year ahead?'],
        ['In your opinion, what strengths does your business possess to rise to the challenges you have outlined above?']: this.state['In your opinion, what strengths does your business possess to rise to the challenges you have outlined above?'],
        ['Can you suggest additional improvements and/or new ideas that could address your company’s challenges?']: this.state['Can you suggest additional improvements and/or new ideas that could address your company’s challenges?'],
      })
    }).then(() => {
      this.setState({
        sendingForm: false
      });
      this.props.toggleLevelCompleteModal(selectedLevel);
    });
  };

  render() {
    const { data, user, game, selectedLevel, isDemoGame, viewportWidth } = this.props;
    const { level1DemoData, manWritingOnPaper } = data;
    const {
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      title,
      objective,
      benefits,
      scoring
    } = level1DemoData.childMarkdownRemark.frontmatter;
    let { tools } = level1DemoData.childMarkdownRemark.frontmatter;
    const { componentHasMounted, sendingForm } = this.state;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if (uniqueDocs.level === selectedLevel) {
          tools.link = uniqueDocs.link;
        }
      });
    }
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if (uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          };
        }
      });
    }

    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 1 Demo" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={1}
          title={title}
          levelPageSummary={levelPageSummary}
          levelIcon={level1Icon}
          levelVideoCode={levelVideoCode}
          isDemoGame={isDemoGame}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge isDemoGame={isDemoGame} challenge={challenge} duration={duration} tools={tools} />
        <SevenQuestions>
          <Form
            name="Level 1 Demo Submission"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}>
            <Input type="hidden" name="form-name" value="Level 1 Demo Submission" />
            <div hidden>
              <Label>
                Don’t fill this out:
                <Input name="bot-field" onChange={this.handleInputChange} />
              </Label>
            </div>
            <div style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
              <Label>
                Game
                <Input type="hidden" name="game" aria-label="game" />
              </Label>
            </div>
            <div style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
              <Label>
                Name
                <Input type="hidden" name="name" aria-label="name" />
              </Label>
            </div>
            <div style={{ position: 'absolute', opacity: 0, zIndex: -1 }}>
              <Label>
                Email
                <Input type="hidden" name="email" aria-label="email" />
              </Label>
            </div>
            <div>
              <Label>
                <div>
                  <QuestionNumber>1.</QuestionNumber>
                  <p dangerouslySetInnerHTML={{ __html: 'Since the emergence of COVID, what are the biggest challenges your company faces in the year ahead?' }} />
                </div>
                <TextArea
                  as="textarea"
                  placeholder="Your answer here"
                  name="Since the emergence of COVID, what are the biggest challenges your company faces in the year ahead?"
                  cols="30"
                  rows="10"
                  aria-label="Your answer here"
                  onChange={this.handleInputChange}
                  value={this.state['Since the emergence of COVID, what are the biggest challenges your company faces in the year ahead?']}
                  required
                />
              </Label>
            </div>
            <div>
              <Label>
                <div>
                  <QuestionNumber>2.</QuestionNumber>
                  <p dangerouslySetInnerHTML={{ __html: "In your opinion, what strengths does your business possess to rise to the challenges you have outlined above?" }} />
                </div>
                <TextArea
                  as="textarea"
                  placeholder="Your answer here"
                  name="In your opinion, what strengths does your business possess to rise to the challenges you have outlined above?"
                  cols="30"
                  rows="10"
                  aria-label="Your answer here"
                  onChange={this.handleInputChange}
                  value={this.state["In your opinion, what strengths does your business possess to rise to the challenges you have outlined above?"]}
                  required
                />
              </Label>
            </div>
            <div>
              <Label>
                <div>
                  <QuestionNumber>3.</QuestionNumber>
                  <p dangerouslySetInnerHTML={{ __html: "Can you suggest additional improvements and/or new ideas that could address your<br />company’s challenges?" }} />
                </div>
                <TextArea
                  as="textarea"
                  placeholder="Your answer here"
                  name="Can you suggest additional improvements and/or new ideas that could address your company’s challenges?"
                  cols="30"
                  rows="10"
                  aria-label="Your answer here"
                  onChange={this.handleInputChange}
                  value={this.state['Can you suggest additional improvements and/or new ideas that could address your company’s challenges?']}
                  required
                />
              </Label>
            </div>
            <SubmitButton type="submit" sendingForm={sendingForm}>
              {sendingForm ? 'Sending' : 'Submit'}
            </SubmitButton>
          </Form>
        </SevenQuestions>
        <InnerWrapper>
          <OverallBenefits isDemoGame={isDemoGame} />
        </InnerWrapper>
        <Inspiration
          img={manWritingOnPaper}
          author="Stephen Covey, <em>7 Habits</em>"
          quote="Begin with the end in mind"
          quoteLargeFontSize="4rem"
          maxWidth="36.563em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const QuestionNumber = styled.span`
  align-items: center;
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

const SevenQuestions = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
`;

const SubmitButton = styled.button`
  align-self: flex-end;
  background-color: #000;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 2.875em;
  outline-color: var(--cx-dark-green);
  text-transform: uppercase;
  width: 9.25em;

  ${({ sendingForm }) =>
    sendingForm &&
    css`
      &:after {
        content: ' .';
        margin-left: -3px;
        animation: dots 1s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
        }
      }
    `}
`;

const Label = styled.label`
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;

  > div {
    align-items: flex-start;
    display: flex;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.375em;

      > span {
        font-weight: 400;
        position: relative;
        top: 0.167em;
      }
    }
  }
`;

const Input = styled.input`
  background-color: #fff;
  font-size: 1.25rem;
  height: 40px;
  outline-color: var(--cx-dark-green);
  padding: 0.5em;
  width: 100%;

  &::placeholder {
    font-size: 1.25rem;
    font-style: italic;
  }
`;

const TextArea = styled(Input)`
  border: 0.063rem solid var(--cx-dark-green);
  height: 15rem;
  margin: 0.95em auto 2.5em;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 73.75em;
  width: 100%;
`;

const Wrapper = styled.section``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

export const Level1DemoQuery = graphql`
  {
    level1DemoData: file(
      sourceInstanceName: { eq: "levelData" }
      relativePath: { eq: "level_01_demo.md" }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    manWritingOnPaper: file(relativePath: { eq: "Shared/Inspiration/man_writing_on_paper.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 590, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level1Demo;
